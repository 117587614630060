.swiper-container {
    margin:0 auto;
    position:relative;
    overflow:hidden;
    /* Fix of Webkit flickering */
    z-index:1;
}
.swiper-container-no-flexbox {
    .swiper-slide {
        float: left;
    }
}
.swiper-container-vertical > .swiper-wrapper{
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}
.swiper-wrapper {
    position:relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-transition-property:-webkit-transform;
    -moz-transition-property:-moz-transform;
    -o-transition-property:-o-transform;
    -ms-transition-property:-ms-transform;
    transition-property:transform;
    
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.swiper-container-android .swiper-slide, .swiper-wrapper {
    -webkit-transform:translate3d(0px,0,0);
    -moz-transform:translate3d(0px,0,0);
    -o-transform:translate(0px,0px);
    -ms-transform:translate3d(0px,0,0);
    transform:translate3d(0px,0,0);
}
.swiper-container-multirow > .swiper-wrapper {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -ms-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    margin: 0 auto;
}
.swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex: 0 0 auto;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
}

/* a11y */
.swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}
.swiper-wp8-vertical {
    -ms-touch-action: pan-x;
    touch-action: pan-x;
}