body{
	padding-top: 120px;
	padding-bottom: 20px;
	&,*{
		-webkit-font-smoothing: antialiased;
	}
}

header{

	-webkit-font-smoothing: antialiased;
	padding: 25px 0;
	height: 120px;
	width: 100%;
	background-color: #fff;
	position: fixed;
	top: 0;
	z-index: 100;
	border-bottom: 1px solid #bebebe;
	overflow: hidden;

	.header-logo{
		padding: 0 30px;
		display: block;
		img{
			display: block;
		}
	}

	.hisconect-brand{
		font-size: 40px;
		padding: 0 30px 5px;
		font-weight: 100;
		line-height: 1;
		text-align: right;
		margin-bottom: 5px;
		span{
			color: @brand-danger;
		}
	}

	.user-logged-in{

		text-align: right;
		padding: 0 30px 0;

		ul{

			margin: 0;

			li{
				line-height: 1;
				border-right: 1px solid #bebebe;
				&:last-child{
					border-right: none;
				}
			}

		}

	}

	.header-menu{
	
		margin-bottom: 0;

		li{

			padding: 25px 0;

			> a {
				font-size: 18px;
				color: #000;
				padding-left: 30px;
				padding-right: 30px;
				border-right: 1px solid #bebebe;
				line-height: 1;
			}

			&:last-child{
				> a{
					border-right: none;
				}
			}

			&.active > a {
				color: @brand-danger;
			}

		}

	}

	.left-header{

		float: left;
		width: 330px;

		@media (max-width: @screen-lg-min) {
			width: 250px;
		}

	}
	.right-header{

		margin-left: 330px;
		@media (max-width: @screen-lg-min) {
			margin-left: 250px;
		}


	}

}

em{
	color: @brand-danger;
	font-style: normal;
	font-weight: bold;
}

.icon{

	display: inline-block;
	background-position: center center;
	background-repeat: no-repeat;

	&.icon-phone{
		background-image: url('../images/icon-phone.png');
		width: 29px;
		height: 29px;
	}

}

.login-page{

	background-color: #000;
	background-image: url('../images/login-bg.jpg');
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center;
	width: 100%;
	top: 0;
	bottom: 0;
	position: absolute;
	padding-top: @line-height-computed*10;

	.login-panel{

		.login-header{

			text-align: center;
			border-bottom: 1px solid #bebebe;
			margin-bottom: @line-height-computed;

			h2{
				font-size: 40px;
				margin: 0;
				margin-bottom: @line-height-computed;
			}

		}

		background-color: #fff;
		padding: @line-height-computed*1.5 @line-height-computed*4;
		width: 100%;

		// position: absolute;
		// top: 50%;
		// overflow: hidden;
		// display: block;
		// -webkit-transform: translateY(-50%);
		// -ms-transform: translateY(-50%); // IE9 only
		// -o-transform: translateY(-50%);
		// transform: translateY(-50%);

	}

	.login-form{

		.form-control{

			text-align: center;
			font-size: 18px;
			height: 45px;
			padding-top: 10px;
			padding-bottom: 10px;

		}

		.btn-login{
			background-color: #000;
			color: #fff;
			padding: 13px 75px;
			margin-top: @line-height-computed/2;
		}

	}

	.forgotten-password{
		margin: @line-height-computed/2 0 0;
		color: @brand-danger;
		display: block;
	}

}

.wrapper{

	min-height: 100vh;

	.sidebar{

		float: left;
		width: 330px;
		position: fixed;
		overflow-y: scroll;
		top: 120px;
		bottom: 0;

		@media (max-width: @screen-lg-min) {
			width: 250px;
		}

		background-color: #000;
		background-image: url('../images/sidebar-bg.jpg');
		background-repeat: no-repeat;
		background-position:0 bottom ;

		.sidebar-header{

			font-size: 21px;
			padding: 20px 30px;
			line-height: 1;
			background-color: #000;
			color: #fff;
			font-weight: 500;
			// border-right: 20px solid #eeeeee;
			z-index: 1;
			position: relative;

		}

		.sidebar-container{
			height: 100%;
			width: 100%;
			position: relative;
		}

		.sidebar-menu{

			border-right: 20px solid #eeeeee;

			li{

				position: relative;
				

				&.active{

					border-bottom: 0;

					a{
						background-color: @brand-danger;
						font-weight: 500;
					}

					&:before{

						content: '\2192';
						color: #fff;
						position: absolute;
						right: -5px;
						z-index: 2;
						top: 0;
						bottom: 0;
						margin: auto;
						font-size: 30px;
						height: 12px;
						line-height: 0.2;

					}
					&:after{

						right: -20px;
						top: 0;
						content: '';
						position: absolute;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 30px 0 30px 20px;
						border-color: transparent transparent transparent @brand-danger;
						-webkit-transform:rotate(360deg);

					}

				}

				a{
					padding: 20px 30px;
					font-size: 21px;
					color: #fff;
					display: block;
					line-height: 1;
					font-weight: 200;
					text-decoration: none;
					border-bottom: 1px solid #5f5f5f;

				}

			}

			#gradient.vertical(#494949, rgba(0,0,0, 0), 25%, 100%);
			min-height: 100%;
			z-index: 2;
			position: relative;
			margin-bottom: 0;

		}

		.sidebar-background{
	
			// background-color: #000;
			// background-image: url('../images/sidebar-bg.jpg');
			// background-repeat: no-repeat;
			// background-position:0 bottom ;
			// background-attachment: fixed;
			// width: 100%;
			// min-height: 100%;
			// position: absolute;
			// top: 0;
			// bottom: 0;
			// z-index: 0;
			// border-right: 20px solid #eeeeee;
			
		}

	}

	.page{

		margin-left: 330px;
		padding: 30px 60px 0;
		@media (max-width: @screen-lg-min) {
			margin-left: 250px;
			padding: 30px 10px 0;
		}

		.save-perimeter{

			min-height: 100vh;

			margin: -30px -20px;
			@media (min-width: @screen-lg) {
				margin: -30px -60px;
			}

			padding: 30px 20px 0;
			@media (min-width: @screen-lg) {
				padding: 30px 60px 0;
			}

		}

		.input-group-addon{
			border: 0;
			color: @brand-danger;
		}

		&.page-profile{

			.page-content{

				margin-bottom: 20px;

				.glyphicon{
					font-size: 20px;
					color: #5f5f5f;
				}

				.company-logo{
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
				}

				@media (min-width: @screen-sm-max) {

					.profile-form-top{

						height: 100px;
						position: relative;
						margin-top: -40px;

						.company-logo{
							position: absolute;
							top: 0px;
							bottom: 15px;
							left: 10px;
							right: 10px;
							background-position: right bottom;
						}

						.form-group{
							position: absolute;
							bottom: 0;
						}

					}

				}
				@media (max-width: @screen-sm-max) {

					.company-logo{
						height: 80px;
						width: 100%;
						margin: 20px 0;
					}

				}
				
			}

		}

		.page-title{

			color: @brand-danger;
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
			padding: 26px 30px;
			background-color: #fff;
			line-height: 1;
			margin-bottom: 30px;

			h1,h2,h3,h4,h5,h6,
			.h1,.h2,.h3,.h4,.h5,.h6{
				margin: 0;
				line-height: 1;
			}

			.list-inline{

				margin: 6px 0;
				text-align: center;

				@media (min-width: @screen-md) {

					float: right;
					text-align: right;

				}

				li {

					padding: 0;

					&.active{

						a{

							color: @brand-danger;
							text-decoration: underline;

						}

					}

					a{

						color: #5f5f5f;
						padding: 0 10px;

					}

				}

			}

		}

	}
	.contract-download{

		p{
			white-space: nowrap;
			width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		p.small{
			color: @brand-danger;
			margin-bottom: 0;
		}
		p.h4{
			color: @text-color;
			margin-top: 0;
		}
		a.btn{
			margin: 7px 0;
			@media (max-width: @screen-sm-max) {
				width: 100%;
			}
		}
	}
	.contract{

		.contract-heading{

			background-color: #eeeeee;
			padding: 10px 15px;	
			font-weight: 500;

		}

		.clause-list{

			margin-bottom: 0;

			p{
				margin-bottom: 0;
				color: #000;
			}

			p + p{
				margin-top: 10px;
			}

			> li{

				border-bottom: 1px solid #c7c7c7;
				padding: 15px 15px 15px 30px;
				position: relative;
				margin-left: 15px;
				margin-right: 15px;

				&:last-child{
					border-bottom: 0;
				}

				ul{
					margin: 10px 0;
				}

				li{
					list-style-type: disc;
					color: @brand-danger;
				}

			}

		}

		.clause-number{

			color: @brand-danger;
			font-size: 21px;
			position: absolute;
			left: 0px;
			top: 15px;
			line-height: 1.15;

		}

	}

	.sub-table-title{

		background-color: #5f5f5f;
		border: 3px solid #fff;
		border-top: none;
		color: #fff;
		padding: 15px 30px;
		font-size: 16px;

	}

	.sub-table-content{

		font-size: 15px;
		padding: 20px 30px;

	}

	.page-content + .page-content{

		margin-top: 20px;

	}

	.table-container{

		overflow: hidden;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
		background-color: #fff;
		margin-bottom: 20px;
		padding: 3px;

	}

	.page-content{

		overflow: hidden;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
		background-color: #fff;
		padding: 20px 30px;

		&.page-content-profile{
			margin-bottom: 20px;
		}

	}

}
.user-profile-view-switch{
	margin: -5px 0;
	.btn{
		border: 0;
		width: 40px;
		height: 40px;
		font-size: 18px;
		&.btn-default{
			color: #bebebe;
		}
	}
}
.user-profiles{

	.single-user-profile{

		background-color: #fff;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
		overflow: hidden;

		.input-group-addon{
			border: 0;
		}

		.img-circle{
			border: 2px solid #dedede;
			max-height: 90px;
			min-width: 90px;
		}

		&.profile-grid-view{

			margin-bottom: 20px;
			padding: 20px 5px 0px 15px;

			.left-panel{

				float: left;
				width: 90px;
				overflow: hidden;

			}
			.right-panel{

				margin-left: 90px;
				overflow: hidden;

			}

		}

		&.profile-list-view{

			td {

				vertical-align: middle;
				border-top: 0;
				text-align: center;

			}

			.form-group{
				margin: 0;
			}

			.img-circle{
				max-height: 90px;
				min-width: 90px;
			}

		}

	}

}
.input-group{

	&.select-fix{

		.input-group-addon{

			position: relative;
			border: 0;

			&:before{
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				width: 20px;
				background-color: #eee;
				right: -20px;
			}

		}

	}

}
.checkbox{

	margin: 0;

	input[type="checkbox"]{

		display: none;

		&:checked + label{

			&:before{
				
				content: "\e013";
				font-family: 'Glyphicons Halflings';
				font-size: 10px;

			}

		}

	}

	label{

		padding-left: 30px;
		color: @gray-light;
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;

		&:after{}

		&:before{

			content: "";
			height: 20px;
			width: 20px;
			padding: 3px 4px;
			left: 0px;
			background-color: @input-bg;
			color: @brand-danger;
			position: absolute;
			border-radius: 3px;

		}

	}

}
.radio{

	margin-left: 10px;
	margin-top: 25px;
	margin-bottom: 15px;

	input[type="radio"]{

		display: none;

		&:checked + label{

			&:after{
				
				content: "";
				background-color: @brand-danger;
				height: 12px;
				width: 12px;
				position: absolute;
				z-index: 10;
				left: 4px;
				border-radius: 100%;
				top: 4px;
				margin: auto;

			}

		}

	}

	label{

		padding-left: 30px;
		color: #000;
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;

		&:after{}

		&:before{

			content: "";
			height: 20px;
			width: 20px;
			left: 0px;
			background-color: @input-bg;
			color: @brand-danger;
			position: absolute;
			border-radius: 100%;

		}

	}

}
.input-group.datepicker{

	.input-group-addon{
		border: none;
		color: @brand-danger;
	}

}
ol{

	li{

		padding: 20px 0;
		border-bottom: 1px solid #c7c7c7;

		em{
			margin-left: 10px;
		}

		&:last-child{

			border: none;

		}

	}

}
a.btn.btn-default{

	border: 0;

	span.glyphicon{

		color: #bebebe;

	}

}
.page-header{
	margin-top: 0;
	border-bottom-color: #bebebe;
	h1,h2,h3,h4,h5,h6,
	.h1,.h2,.h3,.h4,.h5,.h6{
		margin-top: 0;
	}
}
.single-message{

	border-bottom: 1px solid #bebebe;

	.single-message-header{
		padding: 10px 0;
		.sender-name{
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.single-message-body{
		
		>div{
			background-color: #eee;
			padding: 10px 10px 0;
			overflow: hidden;
		}

	}

}
table.people-table{
	margin-bottom: 0;
	.form-control{
		&:focus{
			box-shadow: none;
		}
	}
	.input-group{
		
		input.form-control{
			padding-left: 0;
		}

	}
	.name-field{

		.row{
			margin: 0 -3px;
			>div{
				padding: 0 3px;
			}
		}

	}
	@media (max-width: @screen-lg-min) {
	
		.hide-column, {
			display: none !important;
		}
		.cv-column{
			width: 45px;
			text-align: center;
		}
		.input-group{

			.input-group-addon{
				display: none;
			}
			.form-control:last-child{
			    border-bottom-left-radius: 3px;
			    border-top-left-radius: 3px;
			    padding-left: 12px;
			}

		}

	}
}
table.performance-table{

	margin-bottom: 0;

	thead{

		.col-md-1{
			width: 10%;
			text-align: center;
		}

	}

	tbody{

		tr{

			&.service-total{

				.form-control{

					color: #fff !important;
					font-weight: 500;

					&::-webkit-input-placeholder {
						color: #fff !important;
					}

					&:-moz-placeholder { /* Firefox 18- */
						color: #fff !important;  
					}

					&::-moz-placeholder {  /* Firefox 19+ */
						color: #fff !important;  
					}

					&:-ms-input-placeholder {  
						color: #fff !important;  
					}
				}

				.has-success{

					.form-control{
						background-color: @brand-success;
					}

				}

				.has-error{

					.form-control{
						background-color: @brand-danger;
					}

				}

				.has-warning{

					.form-control{
						background-color: @brand-warning;
					}                 

				}

				.service-level-description{
					
					text-align: right;
					.h3{
						margin: 0;
						font-size: 21px;
					}

				}

			}

			td{

				border-right: 1px solid #eeeeee;
				border-color: #eeeeee;
					
				&:first-child{
					border-right: 0;
				}

				&.service-level-description{

					vertical-align: middle !important;
					font-size: 16px;
					&,em{
						font-weight: 200; 
					}

					p{
						margin-bottom: 0;
					}
					p + p{
						margin-top: 10px;
					}

				}

			}

		}

	}

	.form-group{

		margin-bottom: 0;

		.form-control{

			font-size: 16px;
			text-align: center;
			height: auto;
			padding: 10px 12px;
			border: 2px solid transparent;
			border-radius: 0;

		}

		&.has-success{

			.form-control{
				border: 2px solid darken(@brand-success, 12%);
				color: @brand-success;
				&::-webkit-input-placeholder {
					color: @brand-success;
				}

				&:-moz-placeholder { /* Firefox 18- */
					color: @brand-success;  
				}

				&::-moz-placeholder {  /* Firefox 19+ */
					color: @brand-success;  
				}

				&:-ms-input-placeholder {  
					color: @brand-success;  
				}
			}

		}
		&.has-error{

			.form-control{
				border: 2px solid darken(@brand-danger, 12%);
				color: @brand-danger;
				&::-webkit-input-placeholder {
					color: @brand-danger;
				}

				&:-moz-placeholder { /* Firefox 18- */
					color: @brand-danger;  
				}

				&::-moz-placeholder {  /* Firefox 19+ */
					color: @brand-danger;  
				}

				&:-ms-input-placeholder {  
					color: @brand-danger;  
				}
			}

		}
		&.has-warning{

			.form-control{
				border: 2px solid darken(@brand-warning, 12%);
				color: @brand-warning;
				&::-webkit-input-placeholder {
					color: @brand-warning;
				}

				&:-moz-placeholder { /* Firefox 18- */
					color: @brand-warning;  
				}

				&::-moz-placeholder {  /* Firefox 19+ */
					color: @brand-warning;  
				}

				&:-ms-input-placeholder {  
					color: @brand-warning;  
				}
			}

		}

	}

}
table.endorsement-table{

	th{
		text-align: center;
	}
	td{
		text-align: center;
		vertical-align: middle !important;
		border-color: #bebebe;
	}
	.insert-row{
		background-color: #eeeeee;
		border-bottom: 10px solid @brand-danger;
		td{
			border: 0;
		}
	}
	.added-row{

		&:nth-child(odd){
			background-color: #eeeeee;
		}

	}
	.ref-num{
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: block;
	}
	.form-control{
		background-color: #fff;
		height: auto;
	}

}
table.schedule-table{

	th{
		border: none !important;
	}

	td{
		vertical-align: middle !important;
		text-align: center;
	}

	.comment-block{

		padding: 10px;

		div{

			background-color: #eee;
			padding: 10px 10px 0;
			overflow: hidden;
			text-align: left;

		}

	}

}
table.fee-card{
	border-bottom: 1px solid #9c9c9c;
	margin-bottom: 0;
	width: 100%;
	thead{
		.row-title{
			text-align: left;
		}
		th{
			text-align: center;
		}
	}
	tbody{
		td{
			text-align: center;
			border: none;
			border-right: 1px solid #9c9c9c;
			font-size: 14px;
		}
		.row-title{
			text-align: left;
			font-weight: bold;
			color: @brand-danger;
			background-color: #fff;
		}
		tr{
			border-bottom: none;
			&:nth-child(2n){
				border-bottom: 1px solid #9c9c9c;
			}
			&:nth-child(even){
				background-color: #eeeeee;
			}
		}
	}
}
table{

	th,td{
		padding: 15px 30px;
		// display: inline-block;
	}

	thead{
		background-color: #5f5f5f;
		color: #fff;
		th{
			border: none;
			span{
				font-weight: 500;
				font-size: 14px;
				white-space: nowrap;
				overflow: hidden;
				width: 100%;
				text-overflow: ellipsis;
			}
		}
	}

}

.hiscox-search{

	position: fixed;
	top: 120px;
	bottom: 0;
	width: 100%;

	#mapBlock.fixedPos{
		position: fixed;
		right: 0;
		z-index: 0;
	}

	#searchBlock{
		z-index: 10;
		position: relative;
		background-color: @body-bg;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
	}

	#searchBlock, #mapBlock{

		padding: 0;
		height: 100%;
		.transition(all .35s linear);

	}

	.search-box{

		padding: 20px 30px;
		background-color: #1e1e1e;
		box-shadow: 0 0 5px rgba(0, 50px, 50px, 1);
		position: relative;
		z-index: 1;

		height: 190px;
		.transition(all .35s linear);
		&.filter-toggled{
			height: 390px;
			@media (max-width: @screen-xs-max) {
				height: 590px;
			}
			.transition(all .35s linear);
		}

		.btn-search{
			border-color: @brand-danger;
			&:hover,&:focus{
				border-color: @brand-danger;
			}
		}

		.btn-map-toggle{
			position: absolute;
			top: 25px;
			right: -72px;
			width: 40px;
			height: 40px;
			padding: 0;

			.glyphicon-globe{
				font-size: 26px;
				margin-top: 4px;
			}

			&.toggled{
				right: 20px;
			}
		}
		.filter-toggle-holder{
			margin-bottom: 15px;
			color: #fff;
			font-weight: 500;
		}
		.filter-clear{
			.filter-toggle-holder;
			cursor: pointer;
		}
		.btn-filter-toggle{
			border-radius: 0;
			border: none;
			padding: 0;
			color: #fff;
			font-weight: 500;
			.glyphicon{
				font-size: 12px;
				color: @brand-danger;
			}
		}
		.radio{
			label{
				color: #fff;
			}
		}

	}
	.result-box{

		.container-fluid{
			max-width: 1580px;
		}

		padding: 20px 30px;
		position: absolute;
		top: 190px;
		width: 100%;
		overflow: scroll;
		bottom: 0;
		z-index: 0;

		.transition(all .35s linear);
		
		&.filter-toggled{
			top: 390px;
			@media (max-width: @screen-xs-max) {
				top: 590px;
			}
			.transition(all .35s linear);
		}

		.profile-search-result{

			position: relative;

			.anchor-overlay{
				display: block;
				z-index: 2;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}

			background-color: #fff;
			padding: 15px;
			height: 170px;
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
			margin: 0 0 20px;

			h3{
				margin: 0;
				font-size: 21px;
				color: #000;
				font-weight: 200;

				width: 100%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;

				small{
					font-size: 14px;
					color: @brand-danger;
					text-transform: capitalize;
				}

				a{
					text-decoration: none;
					color: #000;
					&:hover{
						color: @brand-danger;
					}
				}
			}

			.result-address{
				font-weight: 200;
				margin-top: 5px;
			}

			.result-address,
			.result-phone,
			.result-sector{
				width: 100%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				display: inline-block !important;
			}

			.result-sector{
				color: @brand-danger;
			}

			.result-footer{

				left: 15px;
				right: 0;
				bottom: 0;
				position: absolute;
				// height: 29px;
				border-top: 1px solid @gray-lighter;
				padding: 5px 0 0;

				p{
					line-height: 2;
					margin: 0;
					span{
						display: inline-block;
					}
				}
			}

			.left-block, .right-block{
				height: 140px;
				position: relative;
			}

			.left-block{
				width: 140px;
				float: left;
			}

			.right-block{
				padding: 0 0 0 15px;
				margin-left: 140px;
			}

		}

	}

}

.angular-google-map,.angular-google-map-container {
	height: 100%; 
}
.infoWindowContainer{

	background-color: #fff;
	height: 120px;
	width: 250px;
	// box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
	// margin: 0 0 20px;
	position: relative;

	.anchor-overlay{
		display: block;
		z-index: 2;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	
	&,&:hover{
		text-decoration: none;
	}

	&,span{
		display: block;
	}

	.h3{
		margin: 0;
		font-size: 21px;
		color: #000;
		font-weight: 200;

		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		small{
			font-size: 14px;
			color: @brand-danger;
			text-transform: capitalize;
		}
	}

	.result-address{
		font-weight: 200;
		margin-top: 5px;
	}

	.result-address,
	.result-phone,
	.result-sector{
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: inline-block !important;
	}

	.result-sector{
		color: @brand-danger;
	}

	.result-footer{

		left: 0;
		right: 0;
		bottom: 0;
		position: absolute;
		border-top: 1px solid @gray-lighter;
		padding: 5px 0 0;

		.p{
			line-height: 2;
			margin: 0;
			span{
				display: inline-block;
			}
		}
	}

}

.main-contacts{

	li{
		display: block;
		padding: 10px 0;
	}

	.main-contact{

		.img-circle{
			border: 2px solid #dedede;
		}

		.main-contact-name,.main-contact-title,
		.main-contact-number,.main-contact-email{
			width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.main-contact-name{
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 0;
			line-height: 1;
		}
		.main-contact-title{
			font-size: 14px;
			font-weight: 200;
		}
		.main-contact-number{
			color: #5f5f5f;
			margin-bottom: 0;
		}
		.main-contact-email{
			color: @brand-danger;
			line-height: 1;
		}

		.left-block, .right-block{
			height: 90px;
			position: relative;
		}

		.left-block{
			width: 90px;
			float: left;
		}

		.right-block{
			padding: 0 0 0 15px;
			margin-left: 90px;
		}

	}

}
span[class^="icon-"]{
	display: inline-block;
	vertical-align: middle;
}
.sprites(@spritesheet-sprites);