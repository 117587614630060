/*
LESS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: @icon-home-width;
}

The large array-like variables contain all information about a single icon
@icon-home: x y offset_x offset_y width height total_width total_height image_path name;

At the bottom of this section, we provide information about the spritesheet itself
@spritesheet: width height image @spritesheet-sprites;
*/
@icon-building-name: 'icon-building';
@icon-building-x: 34px;
@icon-building-y: 29px;
@icon-building-offset-x: -34px;
@icon-building-offset-y: -29px;
@icon-building-width: 24px;
@icon-building-height: 29px;
@icon-building-total-width: 127px;
@icon-building-total-height: 93px;
@icon-building-image: '../images/sprite.png';
@icon-building: 34px 29px -34px -29px 24px 29px 127px 93px '../images/sprite.png' 'icon-building';
@icon-calendar-red-name: 'icon-calendar-red';
@icon-calendar-red-x: 111px;
@icon-calendar-red-y: 20px;
@icon-calendar-red-offset-x: -111px;
@icon-calendar-red-offset-y: -20px;
@icon-calendar-red-width: 16px;
@icon-calendar-red-height: 17px;
@icon-calendar-red-total-width: 127px;
@icon-calendar-red-total-height: 93px;
@icon-calendar-red-image: '../images/sprite.png';
@icon-calendar-red: 111px 20px -111px -20px 16px 17px 127px 93px '../images/sprite.png' 'icon-calendar-red';
@icon-compass-name: 'icon-compass';
@icon-compass-x: 63px;
@icon-compass-y: 0px;
@icon-compass-offset-x: -63px;
@icon-compass-offset-y: 0px;
@icon-compass-width: 24px;
@icon-compass-height: 24px;
@icon-compass-total-width: 127px;
@icon-compass-total-height: 93px;
@icon-compass-image: '../images/sprite.png';
@icon-compass: 63px 0px -63px 0px 24px 24px 127px 93px '../images/sprite.png' 'icon-compass';
@icon-cv-name: 'icon-cv';
@icon-cv-x: 111px;
@icon-cv-y: 0px;
@icon-cv-offset-x: -111px;
@icon-cv-offset-y: 0px;
@icon-cv-width: 16px;
@icon-cv-height: 20px;
@icon-cv-total-width: 127px;
@icon-cv-total-height: 93px;
@icon-cv-image: '../images/sprite.png';
@icon-cv: 111px 0px -111px 0px 16px 20px 127px 93px '../images/sprite.png' 'icon-cv';
@icon-download-name: 'icon-download';
@icon-download-x: 0px;
@icon-download-y: 73px;
@icon-download-offset-x: 0px;
@icon-download-offset-y: -73px;
@icon-download-width: 19px;
@icon-download-height: 20px;
@icon-download-total-width: 127px;
@icon-download-total-height: 93px;
@icon-download-image: '../images/sprite.png';
@icon-download: 0px 73px 0px -73px 19px 20px 127px 93px '../images/sprite.png' 'icon-download';
@icon-laptop-name: 'icon-laptop';
@icon-laptop-x: 87px;
@icon-laptop-y: 0px;
@icon-laptop-offset-x: -87px;
@icon-laptop-offset-y: 0px;
@icon-laptop-width: 24px;
@icon-laptop-height: 18px;
@icon-laptop-total-width: 127px;
@icon-laptop-total-height: 93px;
@icon-laptop-image: '../images/sprite.png';
@icon-laptop: 87px 0px -87px 0px 24px 18px 127px 93px '../images/sprite.png' 'icon-laptop';
@icon-magnify-lg-name: 'icon-magnify-lg';
@icon-magnify-lg-x: 0px;
@icon-magnify-lg-y: 0px;
@icon-magnify-lg-offset-x: 0px;
@icon-magnify-lg-offset-y: 0px;
@icon-magnify-lg-width: 34px;
@icon-magnify-lg-height: 34px;
@icon-magnify-lg-total-width: 127px;
@icon-magnify-lg-total-height: 93px;
@icon-magnify-lg-image: '../images/sprite.png';
@icon-magnify-lg: 0px 0px 0px 0px 34px 34px 127px 93px '../images/sprite.png' 'icon-magnify-lg';
@icon-mail-name: 'icon-mail';
@icon-mail-x: 87px;
@icon-mail-y: 18px;
@icon-mail-offset-x: -87px;
@icon-mail-offset-y: -18px;
@icon-mail-width: 24px;
@icon-mail-height: 17px;
@icon-mail-total-width: 127px;
@icon-mail-total-height: 93px;
@icon-mail-image: '../images/sprite.png';
@icon-mail: 87px 18px -87px -18px 24px 17px 127px 93px '../images/sprite.png' 'icon-mail';
@icon-map-marker-name: 'icon-map-marker';
@icon-map-marker-x: 0px;
@icon-map-marker-y: 34px;
@icon-map-marker-offset-x: 0px;
@icon-map-marker-offset-y: -34px;
@icon-map-marker-width: 27px;
@icon-map-marker-height: 39px;
@icon-map-marker-total-width: 127px;
@icon-map-marker-total-height: 93px;
@icon-map-marker-image: '../images/sprite.png';
@icon-map-marker: 0px 34px 0px -34px 27px 39px 127px 93px '../images/sprite.png' 'icon-map-marker';
@icon-marker-name: 'icon-marker';
@icon-marker-x: 87px;
@icon-marker-y: 35px;
@icon-marker-offset-x: -87px;
@icon-marker-offset-y: -35px;
@icon-marker-width: 17px;
@icon-marker-height: 24px;
@icon-marker-total-width: 127px;
@icon-marker-total-height: 93px;
@icon-marker-image: '../images/sprite.png';
@icon-marker: 87px 35px -87px -35px 17px 24px 127px 93px '../images/sprite.png' 'icon-marker';
@icon-people-name: 'icon-people';
@icon-people-x: 63px;
@icon-people-y: 24px;
@icon-people-offset-x: -63px;
@icon-people-offset-y: -24px;
@icon-people-width: 24px;
@icon-people-height: 19px;
@icon-people-total-width: 127px;
@icon-people-total-height: 93px;
@icon-people-image: '../images/sprite.png';
@icon-people: 63px 24px -63px -24px 24px 19px 127px 93px '../images/sprite.png' 'icon-people';
@icon-phone-name: 'icon-phone';
@icon-phone-x: 63px;
@icon-phone-y: 43px;
@icon-phone-offset-x: -63px;
@icon-phone-offset-y: -43px;
@icon-phone-width: 21px;
@icon-phone-height: 21px;
@icon-phone-total-width: 127px;
@icon-phone-total-height: 93px;
@icon-phone-image: '../images/sprite.png';
@icon-phone: 63px 43px -63px -43px 21px 21px 127px 93px '../images/sprite.png' 'icon-phone';
@icon-plus-white-name: 'icon-plus-white';
@icon-plus-white-x: 37px;
@icon-plus-white-y: 73px;
@icon-plus-white-offset-x: -37px;
@icon-plus-white-offset-y: -73px;
@icon-plus-white-width: 17px;
@icon-plus-white-height: 17px;
@icon-plus-white-total-width: 127px;
@icon-plus-white-total-height: 93px;
@icon-plus-white-image: '../images/sprite.png';
@icon-plus-white: 37px 73px -37px -73px 17px 17px 127px 93px '../images/sprite.png' 'icon-plus-white';
@icon-red-phone-name: 'icon-red-phone';
@icon-red-phone-x: 34px;
@icon-red-phone-y: 0px;
@icon-red-phone-offset-x: -34px;
@icon-red-phone-offset-y: 0px;
@icon-red-phone-width: 29px;
@icon-red-phone-height: 29px;
@icon-red-phone-total-width: 127px;
@icon-red-phone-total-height: 93px;
@icon-red-phone-image: '../images/sprite.png';
@icon-red-phone: 34px 0px -34px 0px 29px 29px 127px 93px '../images/sprite.png' 'icon-red-phone';
@icon-upload-white-name: 'icon-upload-white';
@icon-upload-white-x: 19px;
@icon-upload-white-y: 73px;
@icon-upload-white-offset-x: -19px;
@icon-upload-white-offset-y: -73px;
@icon-upload-white-width: 18px;
@icon-upload-white-height: 19px;
@icon-upload-white-total-width: 127px;
@icon-upload-white-total-height: 93px;
@icon-upload-white-image: '../images/sprite.png';
@icon-upload-white: 19px 73px -19px -73px 18px 19px 127px 93px '../images/sprite.png' 'icon-upload-white';
@icon-x-red-name: 'icon-x-red';
@icon-x-red-x: 54px;
@icon-x-red-y: 73px;
@icon-x-red-offset-x: -54px;
@icon-x-red-offset-y: -73px;
@icon-x-red-width: 17px;
@icon-x-red-height: 17px;
@icon-x-red-total-width: 127px;
@icon-x-red-total-height: 93px;
@icon-x-red-image: '../images/sprite.png';
@icon-x-red: 54px 73px -54px -73px 17px 17px 127px 93px '../images/sprite.png' 'icon-x-red';
@spritesheet-width: 127px;
@spritesheet-height: 93px;
@spritesheet-image: '../images/sprite.png';
@spritesheet-sprites: @icon-building @icon-calendar-red @icon-compass @icon-cv @icon-download @icon-laptop @icon-magnify-lg @icon-mail @icon-map-marker @icon-marker @icon-people @icon-phone @icon-plus-white @icon-red-phone @icon-upload-white @icon-x-red;
@spritesheet: 127px 93px '../images/sprite.png' @spritesheet-sprites;

/*
The provided classes are intended to be used with the array-like variables

.icon-home {
  .sprite-width(@icon-home);
}
.icon-email {
  .sprite(@icon-email);
}
*/
.sprite-width(@sprite) {
  width: extract(@sprite, 5);
}

.sprite-height(@sprite) {
  height: extract(@sprite, 6);
}

.sprite-position(@sprite) {
  @sprite-offset-x: extract(@sprite, 3);
  @sprite-offset-y: extract(@sprite, 4);
  background-position: @sprite-offset-x @sprite-offset-y;
}

.sprite-image(@sprite) {
  @sprite-image: extract(@sprite, 9);
  @sprite-image-bare: ~`"@{sprite-image}".slice(1, -1)`;
  background-image: url(@sprite-image-bare);
}

.sprite(@sprite) {
  .sprite-image(@sprite);
  .sprite-position(@sprite);
  .sprite-width(@sprite);
  .sprite-height(@sprite);
}

/*
The `.sprites` mixin generates identical output to the CSS template
  but can be overridden inside of LESS

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

.sprites(@spritesheet-sprites);
*/
.sprites(@sprites, @i: 1) when (@i <= length(@sprites)) {
  @sprite: extract(@sprites, @i);
  @sprite-name: e(extract(@sprite, 10));
  .@{sprite-name} {
    .sprite(@sprite);
  }
  .sprites(@sprites, @i + 1);
}
